<template>
  <div class="pageGaeRecord">
    <!--手动开闸记录-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="gaeRecord">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.park_name')">
                <el-autocomplete
                  class="inline-input"
                  v-model.trim="modelvalue"
                  :fetch-suggestions="querySearchAsyncCar"
                  placeholder="请输入内容"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelectCar"
                ></el-autocomplete>
              </el-form-item>

              <!-- <el-form-item label="开闸类型">
            <el-select v-model.trim="formInline.openType" placeholder size="12" style="width:190px">
              <el-option
                :label="value.desc"
                :value="value.code"
                v-for="value in timeList"
                :key="value.code"
              ></el-option>
            </el-select>
          </el-form-item>-->
              <el-form-item :label="$t('searchModule.Operator')">
                <el-autocomplete
                  class="inline-input"
                  v-model="operatorName"
                  :fetch-suggestions="querySearchOperatorName"
                  placeholder="请输入内容"
                  value-key="msUserName"
                  :trigger-on-focus="false"
                  @select="handleSelectOperatorName"
                ></el-autocomplete>
              </el-form-item>

              <!-- <el-form-item :label="$t('searchModule.Operator')">
            <el-select v-model.trim="formInline.operatorId" filterable size="15">
              <el-option label="全部" value></el-option>
              <el-option
                :label="value.operationName"
                :value="value.operationId"
                :key="value.operationId"
                v-for="value in roleList"
              ></el-option>
            </el-select>
          </el-form-item>-->
              <!-- <el-form-item :label="$t('searchModule.Opening_time')">
            <el-date-picker
              v-model="valueDate"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>-->

              <!-- <el-form-item :label="$t('searchModule.Opening_time')">
                <el-date-picker
                  v-model="date1"
                  type="datetime"
                  placeholder="选择日期"
                  @change="setStartTime"
                ></el-date-picker
                >至
                <el-date-picker
                  v-model="date2"
                  type="datetime"
                  placeholder="选择日期"
                  @change="setEndTime"
                ></el-date-picker>
              </el-form-item> -->
              <el-form-item :label="$t('searchModule.time_slot')">
                <timeRangePick
                  @timeChange="timeChange"
                  ref="timeRangePicker"
                  :defalutDate="defalutDate"
                />
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="searchData(1)"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <exportFile
                style="display: inline-block"
                :exportData="exportData"
                v-if="$route.meta.authority.button.export"
              ></exportFile>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          min-height="400"
        >
          <el-table-column
            type="index"
            :label="$t('list.index')"
            :index="indexMethod"
            width="70"
          ></el-table-column>
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            align="center"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
          <!-- <el-table-column prop="img" label="图片" header-align="center" align="center">
            <template slot-scope="scope">
              <el-popover placement="top-start" title trigger="hover">
                <img :src="scope.row.picUrl" alt style="width: 150px;height: 150px" />
                <img slot="reference" :src="scope.row.picUrl" style="width: 30px;height: 30px" />
              </el-popover>
            </template>
          </el-table-column>-->
          <el-table-column header-align="center" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="detailShow(scope.row)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!--分页器-->
      <div class="pagerWrapper">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="抬杆记录"
      :visible.sync="dialogTableVisible"
      class="dialogClass"
    >
      <div v-if="pic" class="picBox">
        <img :src="pic" width="60%" height="40%" @click="showBigPic(pic)" />
      </div>
      <div v-if="pic" class="pciTile">车辆图</div>
      <div v-if="!pic" style="text-align: center">无数据</div>
      <div class="btn">
        <el-button @click="consoleDetail()">关闭</el-button>
      </div>
    </el-dialog>
    <!-- <el-dialog title="抬杆记录" :visible.sync="dialogTableVisible">
      <el-row>
        <el-col :span="12" v-for="(value,index) in entryPic" :key="index">
          <div class="picBox">
            <img :src="value.picUrl" width="60%" height="40%" @click="showBigPic(value.picUrl)" />
          </div>
          <div class="pciTile">
            {{
            value.imageType == 1
            ? '全景照片'
            : value.imageType == 2
            ? '进场车辆图'
            : value.imageType == 3
            ? '进场车牌特写图'
            : ''
            }}
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12" v-for="(value,index) in exitPic" :key="index">
          <div class="picBox">
            <img :src="value.picUrl" width="60%" height="40%" @click="showBigPic(value.picUrl)" />
          </div>
          <div class="pciTile">
            {{
            value.imageType == 1
            ? '全景照片'
            : value.imageType == 2
            ? '出场车辆图'
            : value.imageType == 3
            ? '出场车牌特写图'
            : ''
            }}
          </div>
        </el-col>
      </el-row>
      <div v-if="exitPic.length==0 && entryPic.length==0" style="text-align: center">无数据</div>
      <div class="btn">
        <el-button @click="consoleDetail()">关闭</el-button>
      </div>
    </el-dialog> -->
    <transition name="fade">
      <div
        class="mask"
        v-show="bigPic"
        :style="{ 'z-index': '10000', 'background-image': picUrl }"
        @click="bigPic = false"
      ></div>
    </transition>
  </div>
</template>

<script>
import timeRangePick from "@/components/timePicker";
import { dateFormat, exportExcel, exportExcelNew } from "@/common/js/public.js";
import exportFile from "@/components/exportFile/exportFile.vue";
export default {
  name: "gateRecord",
  data() {
    let date1 = new Date();
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    let startTime = new Date(date1.getTime() - 3600 * 1000 * 24 * 30);
    // console.log("--startTime", startTime);
    let date = new Date();
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    let endTime = date;
    return {
      defalutDate: [],
      dialogTableVisible: false,
      exportData: {},
      date1: startTime,
      date2: endTime,
      modelvalue: "",
      operatorName: "",
      picUrl: "",
      bigPic: false,
      tableCols: [
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        // {
        //   prop: "plateNumber",
        //   label: this.$t("list.plate_number"),
        //   formatter: (row) => {
        //     return row.plateNumber && row.plateNumber.indexOf('无') != -1 ? "无牌车" : row.plateNumber;
        //   },
        //   width: "",
        // },
        // {
        //   prop: "entryTime",
        //   label: this.$t("list.entry_time"),
        //   width: "",
        // },
        // {
        //   prop: "exitTime",
        //   label: this.$t("list.Appearance_time"),
        //   width: "",
        // },
        // {
        //   prop: "shouldPay",
        //   label: this.$t("list.amount_receivable"),
        //   width: "",
        //   formatter: (row, column) => {
        //     if (row.shouldPay) {
        //       return Number(row.shouldPay / 100).toFixed(2);
        //     } else {
        //       return "0";
        //     }
        //   },
        // },
        // {
        //   prop: "parkChannelName",
        //   label: this.$t("list.Channel_name"),
        //   width: "",
        // },
        // {
        //   prop: "doorType",
        //   label: "出入口类型",
        //   width: "",
        //   formatter: (row) => {
        //     if (row.doorType == 0) {
        //       return "入口";
        //     }
        //     if (row.doorType == 1) {
        //       return "出口";
        //     }
        //   },
        // },
        // {
        //   prop: "openType",
        //   label: "开闸类型",
        //   width: "",
        //   formatter: (row) => {
        //     if (row.openType == 0) {
        //       return "岗亭开闸";
        //     }
        //   },
        // },
        {
          prop: "openTime",
          label: "开闸时间",
          width: "",
        },
        {
          prop: "reason",
          label: "开闸原因",
          width: "120",
          // formatter: row => {
          //   if (/无/g.test(row.plateNumber)) {
          //     return "无牌车";
          //   } else {
          //     return row.plateNumber;
          //   }
          // }
        },
        // {
        //   prop: "remark",
        //   label: "备注",
        //   width: "",
        // },
        {
          prop: "operator",
          label: this.$t("list.Operator"),
          width: "",
        },
      ],

      valueDate: "",
      formInline: {
        parkId: "",
        // openType: "0",
        operatorId: "",
        // startTime: dateFormat(startTime),
        // endTime: dateFormat(endTime),
        startTime: "",
        endTime: "",
        name: `抬杆记录`,
        specialOpen: 0,
      },
      timeList: [
        {
          code: "0",
          desc: "岗亭开闸",
        },
      ],
      tableData: [],
      pageSize: 15,
      page: 1,
      loading: false,
      total: 0,
      roleList: [],
      // entryPic: [],
      // exitPic: [],
      pic: "",
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
    operatorName(newVal) {
      if (!newVal) {
        this.formInline.operatorId = "";
      }
    },
  },
  methods: {
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    showBigPic(url) {
      // console.log("sdad");
      if (!url) {
        return false;
      }
      this.picUrl = `url("${url}")`;
      this.bigPic = true;
    },
    setStartTime(value) {
      this.formInline.startTime = dateFormat(value);
      // this.exportData.data.startTime = dateFormat(value);
    },
    setEndTime(value) {
      this.formInline.endTime = dateFormat(value);
      // this.exportData.data.endTime = dateFormat(value);
    },
    consoleDetail() {
      this.pic = "";
      this.dialogTableVisible = false;
    },
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time =
          new Date(this.formInline.endTime) -
          new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        if (time >= 31) {
          this.$alert("查询时间不能大于31天");
          return false;
        } else {
          return true;
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    indexMethod(index) {
      return index + 1;
    },
    // 首页列表查询
    searchData(tag) {
      if (tag == 1) {
        this.page = 1;
      }
      if (this.modelvalue && !this.formInline.parkId) {
        this.$message.error("请输入并选择车场");
        this.modelvalue = "";
        return;
      }
      if (this.operatorName && !this.formInline.operatorId) {
        this.$message.error("请输入并选择操作人");
        this.operatorName = "";
        return;
      }
      // console.log(
      //   "时间",
      //   this.valueDate == null || this.valueDate == ""
      //     ? ""
      //     : dateFormat(this.valueDate[0], "yyyy-MM-dd")
      // );
      let flag = this.showLog();
      if (!flag) {
        return;
      }
      this.$axios
        .get("/acb/2.0/bacb/parkOpenRecord/list", {
          data: {
            // 特殊开闸 1：特殊放行记录(默认) 0：抬杆记录
            specialOpen: 0,
            pageNum: this.page,
            pageSize: this.pageSize,
            openType: this.formInline.openType,
            startTime: this.formInline.startTime,
            endTime: this.formInline.endTime,
            parkId: this.formInline.parkId,
            operatorId: this.formInline.operatorId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.tableData = [];
            this.total = 0;
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    exportFile() {
      // console.log(
      //   "时间",
      //   this.valueDate == null || this.valueDate == ""
      //     ? ""
      //     : dateFormat(this.valueDate[0], "yyyy-MM-dd")
      // );
      let opt = {};
      opt = {
        // 特殊开闸 1：特殊放行记录(默认) 0：抬杆记录
        specialOpen: 0,
        pageNum: this.page,
        pageSize: this.pageSize,
        openType: this.formInline.openType,
        startTime:
          this.valueDate == null || this.valueDate == ""
            ? ""
            : dateFormat(this.valueDate[0], "yyyy-MM-dd"),
        endTime:
          this.valueDate == null || this.valueDate == ""
            ? ""
            : dateFormat(this.valueDate[1], "yyyy-MM-dd"),
        parkId: this.formInline.parkId,
        operatorId: this.formInline.operatorId,
      };

      // /acb/2.0/specialplate/export
      exportExcelNew("/acb/2.0/bacb/parkOpenRecord/exportRecord", opt, "get");
    },
    querySearchAsyncCar(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/bacb/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: this.modelvalue,
            slaveRelations: "0,1",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.formInline.parkId = "";
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 获取停车场详情图片
    // getPic(entryOprNum, exitOprNum) {
    //   // this.entryPic = [];
    //   // this.exitPic = [];
    //   this.pic = "";
    //   // console.log(this.pic);
    //   if (entryOprNum) {
    //     this.getPicAsnc(entryOprNum).then((res) => {
    //       if (res.state == 0) {
    //         this.parsePicResult(res);
    //         if (!this.pic && exitOprNum) {
    //           this.getPicAsnc(exitOprNum).then((res1) => {
    //             if (res1.state == 0) {
    //               this.parsePicResult(res1);
    //             } else {
    //               this.$alert(res1.desc, this.$t('pop_up.Tips'), {
    //                 confirmButtonText: this.$t('pop_up.Determine'),
    //               });
    //             }
    //           });
    //         }
    //       }
    //     });
    //   } else {
    //     if (exitOprNum) {
    //       this.getPicAsnc(exitOprNum).then((res1) => {
    //         if (res1.state == 0) {
    //           this.parsePicResult(res1);
    //         } else {
    //           this.$alert(res1.desc, this.$t('pop_up.Tips'), {
    //             confirmButtonText: this.$t('pop_up.Determine'),
    //           });
    //         }
    //       });
    //     }
    //   }
    // },

    // async getPicAsnc(oprNum) {
    //   return this.$axios.get("/acb/2.0/bacb/parkRecord/picture/" + oprNum);
    // },

    // parsePicResult(res) {
    //   if (res && res.value && res.value.length) {
    //     res.value.forEach((element) => {
    //       if (element.imageType == 2) {
    //         this.pic = element.picUrl;
    //         // console.log(this.pic);
    //         return true;
    //       }
    //     });
    //   }
    //   return false;
    // },

    handleSelectCar(item) {
      this.formInline.parkId = item.parkId;
    },
    /* 商户名称的下拉框 */
    getRoleList() {
      this.$axios
        .get("/acb/2.0/operation/nameList", {
          data: {},
        })
        .then((res) => {
          this.loading = false;
          if (res.state == 0) {
            this.roleList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },

    querySearchOperatorName(queryString, cb) {
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/bacb/msUserPark/getOption";
      this.$axios
        .get(url, {
          data: {
            msUserName: queryString,
            parkId: "",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelectOperatorName(item) {
      this.formInline.operatorId = item.msUserId;
    },
    detailShow(dataRow) {
      // this.getPic(dataRow.entryOprNum, dataRow.exitOprNum);
      this.pic = dataRow.picUrl;
      this.dialogTableVisible = true;
    },
    dataTimeRest() {
      let nowDate = new Date();
      let year = nowDate.getFullYear();
      let month =
        nowDate.getMonth() + 1 < 10
          ? "0" + (nowDate.getMonth() + 1)
          : nowDate.getMonth() + 1;
      let day =
        nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate.getDate();
      let dateStr = year + "-" + month + "-" + day;
      return dateStr;
    },
  },
  components: {
    exportFile,
    timeRangePick,
  },
  created() {
    this.getRoleList();

    this.defalutDate = [
      this.dataTimeRest() + " 00:00:00",
      this.dataTimeRest() + " 23:59:59",
    ];
  },
  mounted() {
    // console.log("权限为==>", this.$route.meta.authority);
    this.exportData = {
      // /acb/2.0/payOrder/payOrderList/export
      url: "/acb/2.0/bacb/parkOpenRecord/exportRecord",
      methods: "post",
      data: this.formInline,
      // data: {
      //   parkId: this.formInline.parkId,
      //   startTime: dateFormat(this.formInline.startTime),
      //   endTime: dateFormat(this.formInline.endTime),
      //   // openType: this.formInline.openType,
      //   operatorId: this.formInline.operatorId,
      //   name: `特殊放行记录`,
      // },
    };
    this.searchData();
  },
  computed: {},
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  // height: 35px;
}

.pageGaeRecord {
  overflow: hidden;

  .pagerWrapper {
    text-align: right;
    margin-top: 28px;
    font-size: 12px;

    .export {
      font-size: 12px;
      border: 1px solid #0099FA;
      border-radius: 4px;
      color: #0099FA;
      padding: 8px 13px;
      cursor: pointer;

      .iconfont {
        margin-right: 14px;
      }
    }
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6) no-repeat center / contain;
  overflow: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.picBox {
  padding-top: 50px;
  box-sizing: border-box;
  text-align: center;
}

.pciTile {
  text-align: center;
  margin-top: 15px;
}

.btn {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
</style>
<style lang="stylus" scoped>
/deep/ .dialogClass .el-dialog__header {
  padding: 0 12px;
  // background-color: #3F4A56;
  height: 52px;
  line-height: 50px;
  border-radius: 3px 3px 0 0;
}

/deep/.dialogClass .el-dialog__title {
  font-size: 16px;
  font-weight: 700;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #323233;
}

/deep/.dialogClass .el-dialog__headerbtn .el-dialog__close {
  color: #323233;
  transform: translateY(-50%);
}
</style>
